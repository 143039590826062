// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
$headings-font-family: "Playfair Display";
$enable-rounded: false;
$card-height: 100%;

$white: #ffffff;
$light: #fdf8fc;
$dark: #020345;
$primary: #bf0a8f;
$secondary: #fbccdc;
$info: #0d78e2;
$success: #6cb211;
$warning: #ff9d00;
$danger: #ce2533;

$theme-colors: (
  "light": $light,
  "dark": $dark,
  "primary": $primary,
  "secondary": $secondary,
  "info": $info,
  "success": $success,
  "warning": $warning,
  "danger": $danger,
);

// 3. Include remainder of required Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

// 4. Include any optional Bootstrap CSS as needed
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/helpers";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/dropdown";

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../node_modules/bootstrap/scss/utilities/api";

// 6. Add additional custom code here
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");

nav.navbar {
  position: sticky !important;
  top: 0;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  border-bottom: 1px solid $primary;
}

nav.navbar .active,
nav.navbar a:hover,
footer a:hover {
  color: $primary !important;
}

nav.navbar a.btn:hover {
  color: $white !important;
}

footer a {
  color: $white !important;
}

#user-manuals a {
  text-decoration: none;
  color: #6c757d;
}

#user-manuals a:hover {
  color: #990872;
}

section {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.card-link {
  text-decoration: none;
}

.img-grayscale {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

iframe {
  aspect-ratio: 4 / 3;
  height: 100%;
  width: 100%;
}
